$base: #263238;
$lite: #fff;
$brand: #ff3d00;
$size: 48px;

.loader_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  border-radius: 20px;
  .loader {
    width: $size;
    height: $size;
    border: 3px solid $lite;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: rotation 1s linear infinite;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: $brand;
      width: $size/3;
      height: $size/3;
      transform: translate(-50%, 50%);
      border-radius: 50%;
    }
  }
  &.loader_hidden {
    animation-name: easeOut;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    z-index: -1;
  }

  &.loader_show {
    animation-name: easeIn;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    z-index: 5;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes easeIn {
  0% {
    display: flex;
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes easeOut {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
