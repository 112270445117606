$clr-grey: #f4f7fb;
$clr-orange: #f07808;
$clr-black: #3c3c3c;
#service_wrapp {
  background-color: white;
  .container {
    .cards_wrapp {
      .card {
        width: 30%;
        text-align: center;
        cursor: pointer;
        .content {
          font-size: 20px;
          font-weight: 600;
        }
        img {
          width: 100%;
          border-radius: 20px;
        }
        span {
          font-size: 48px;
          color: $clr-orange;
        }
      }
    }
  }
}

#route_wrapp {
  .calculate_wrapp {
    min-width: 600px;
    // filter: drop-shadow(0px 13px 29px rgba(0, 0, 0, 0.25));
    font-size: 20px;
    font-weight: 800;
    z-index: 1;
    // margin: 0 20px;
    .calculate,
    .result {
      background: white;
      padding: 20px 40px;
      border-radius: 20px 20px 0 0;
      font-size: 20px;
      font-weight: 800;
      .distance {
        margin: 0 40px;
      }
      .result_input {
        border-radius: 20px;
        padding: 10px 20px;
        border: 1px solid #b6b6b6;
        width: 100%;
      }
      .ant-select {
        width: 100%;
      }
      img {
        align-self: flex-end;
        height: 30px;
      }
      label {
        padding-left: 10px;
        margin-bottom: 5px;
        color: $clr-black;
      }
      h5 {
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
      }
      .input {
        width: 40%;
      }
    }
    .methods {
      width: 100%;

      .active {
        background-color: $clr-orange;
      }
      div {
        cursor: pointer;
        padding: 5px 0;
        color: white;
        background-color: $clr-black;
        width: 50%;
        text-align: center;
        &:nth-child(1) {
          border-radius: 10px 0 0 10px;
        }

        &:nth-child(2) {
          border-radius: 0 10px 10px 0;
        }
      }
    }

    .sumbit {
      width: 100%;
      background-color: $clr-orange;
      color: white;
      padding: 20px 0;
      cursor: pointer;

      text-align: center;
      border-radius: 0 0 20px 20px;
    }
  }
}

#statistic_wrapp {
  background-color: white;
  .statistic {
    height: 100%;
    padding: 40px 30px;
    .row {
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
      color: $clr-black;
      h1 {
        color: $clr-orange;
      }
    }
  }
}

#table_wrapp {
  font-size: 26px;

  
  .rounded {
    border-top-left-radius: 50px;
  }
  .col {
    padding: 20px 0;
    text-align: center;
    width: 33.333%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    &:nth-child(n + 2),
    &:nth-child(1) {
      border-right: 1px solid $clr-grey;
    }
    h4{
      margin: 0;
    }
  }
  .row {
    width: 100%;
    border-bottom: 1px solid $clr-grey;
  }
  .head {
    width: 100%;
    color: white;
    .col {
      &:nth-child(1) {
        background-color: $clr-orange;
        border-top-left-radius: 50px;
      }
      &:nth-child(2) {
        background-color: #f89539;
        border-top-right-radius: 50px;
      }
    }
  }
}
.contact_form {
  background-color: white;
  border-radius: 20px;
  height: 100%;
  padding: 40px;
  color: $clr-black;
  .title {
    h3 {
      margin: 0;
    }
    img {
      width: 48px;
      height: 48px;
    }
  }

  .form_item {
    margin: 20px 0;
    margin-top: 0;
    label {
      margin-bottom: 10px;
    }
    input {
      border: 1px solid #bfbfbf;
      border-radius: 10px;
      padding: 10px;
      font-size: 20px;
      outline: none;
    }
    button {
      background-color: $clr-orange;
      padding: 10px;
      font-size: 20px;
      border-radius: 10px;
      color: white;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
}
#footer_wrapp {
  background-color: $clr-black;
  color: white;
  font-weight: bold;
  font-size: 20px;
  .logo{
    margin: 20px auto;
    width: 100px !important;
  }
  .footer {
    img {
      width: 28px;
    }
    .footer_col{
      margin: 0 20px;
    }
    .social_media{
      a{
        margin: 0 10px;
      }
    }
    .anchors{
      span{
        cursor: pointer;
        transition: 0.2s all;
        &:hover{
          color: #f07808;
        }
      }
    }
    .contacts_wrapp {
      margin-right: 20px;
      .contact {
        margin-bottom: 30px;
        span {
          color: $clr-orange;
          cursor: pointer;
        }
        img {
          width: 48px;
          height: 48px;
          margin-right: 10px;
        }
      }
    }

  }
}
