* {
}
.main_wrapp {
  max-width: 1000px;
  width: 70%;
  margin-top: 2%;
  left: 50%;
  .collapse_wrapp {
    margin-bottom: 20px;
    width: 100%;

    :where(.css-dev-only-do-not-override-1m62vyb).ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header {
      background-color: #6475a2;
      color: #fcfcfc !important;
    }

    :where(.css-dev-only-do-not-override-1m62vyb).ant-collapse
      > .ant-collapse-item:last-child,
    :where(.css-dev-only-do-not-override-1m62vyb).ant-collapse
      > .ant-collapse-item:last-child
      > .ant-collapse-header {
      border-radius: 10px !important;
      border: none !important;
    }

    :where(.css-dev-only-do-not-override-1m62vyb).ant-collapse {
      border: none !important;
    }

    :where(.css-dev-only-do-not-override-1jr9qlj).ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header {
      color: #fcfcfc !important;
      font-weight: 600;
      font-size: 18px;
    }
  }
}
.tasks_wrapp {
  :where(.css-dev-only-do-not-override-1jr9qlj)[class^="ant-table"]
    [class^="ant-table"],
  :where(.css-dev-only-do-not-override-1jr9qlj)[class*=" ant-table"]
    [class^="ant-table"],
  :where(.css-dev-only-do-not-override-1jr9qlj)[class^="ant-table"]
    [class*=" ant-table"],
  :where(.css-dev-only-do-not-override-1jr9qlj)[class*=" ant-table"]
    [class*=" ant-table"] {
    transition: 0.3s;
    :hover {
      cursor: pointer;
      transform: scale(1.002);
      transition: 0.3s;
    }
  }
}

.students_wrapp {
  img {
    width: 200px;
  }
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_col {
  display: flex;
  flex-direction: column;
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.flex_end {
  display: flex;
  justify-content: flex-end;
}

.primary_btn {
  border-radius: 10px;
  background: #00abe4;
  box-shadow: 0px 2px 4px 0px rgba($color: #3c3c3c, $alpha: 0.25);
  border: none;
  margin: 5px;
  color: white;
  cursor: pointer;
  min-width: 100px;
  min-height: 30px;
  transition: 0.5s;
  &:disabled{
    background: #b8ccd3;
    cursor:no-drop;
    box-shadow: none;
    transition: 0.5s;
  }
}
.danger_btn {
  border-radius: 10px;
  background: #f38681;
  box-shadow: 0px 2px 4px 0px rgba($color: #3c3c3c, $alpha: 0.25);
  border: none;
  margin: 5px;
  color: white;
  cursor: pointer;
  min-width: 100px;
  min-height: 30px;
}
.primary_btn_outline {
  border-radius: 10px;
  border: solid 1px #00abe4;
  box-shadow: 0px 2px 4px 0px rgba($color: #3c3c3c, $alpha: 0.25);
  color: #3c3c3c;
  background-color: #fcfcfc;
  cursor: pointer;
  min-width: 100px;
  min-height: 30px;
}

.w100 {
  width: 100%;
}
.Left {
  float: left;
}
.right {
  float: right;
}