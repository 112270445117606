$clr-grey: #f4f7fb;
$clr-orange: #f07808;
$clr-black: #3c3c3c;
.step_wrapp {
  background-color: white;
  margin-bottom: 40px;
  padding: 40px 100px;
  h5 {
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
  }
  .calculate {
    .arrow_bk {
      img {
        transition: 0.2s all;
        cursor: pointer;
        &:hover {
          transform: translateX(-5px);
        }
      }
    }
  }
  .step_head {
    padding: 10px;
    width: 150px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    background-color: $clr-orange;
    border-radius: 20px;
    text-align: center;
  }
  .step_content {
    font-size: 20px;
    font-weight: bold;
    text-align: justify;
    padding: 0 50px;
    // max-width: 400px;
  }

  .contact_form {
    min-width: 600px;
    padding-bottom: 0;
    border-radius: 20px 20px 0 0;
    label {
      padding-left: 10px;
      margin-bottom: 5px;
      color: #3c3c3c;
      font-weight: 800;
    }
  }
  .cards_wrapp {
    margin: 0 20px;
    filter: drop-shadow(0px 13px 29px rgba(0, 0, 0, 0.25));
  }
  .submit {
    width: 100%;
    background-color: #f07808;
    color: white;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    border-radius: 0 0 20px 20px;
    font-weight: 600;
    font-size: 18px;
  }
  
}
.notification_icon{

  img{
    height: 30px;
    
  }
}
.msg_wrapp{
  padding-top: 20px;
  span{
    font-size: 20px;
  }
  img{
    margin-right: 20px;
    max-height: 100%;
    height: 50px;
  }
}


img {
  max-width: 100% !important;
}

