$clr-grey: #f4f7fb;
$clr-orange: #f07808;
$clr-black: #3c3c3c;

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  background-color: #f4f7fb;
  max-width: 100vw;
}

h1 {
  font-size: 80px;
}
h2 {
  font-size: 60px;
}
h3 {
  font-size: 48px;
}
h4 {
  font-size: 36px;
}
h5 {
  font-size: 28px;
}
ul {
  margin: 0;
  padding: 0;
}
.button {
  background-color: $clr-orange;
  color: white;
}

#main_wrapper {
  max-width: 100vw;
}

.wrapp_title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  h5,
  h4,
  h3 {
    margin: 0;
  }
  h5 {
    color: $clr-orange;
  }
}
.wrapper {
  
  .container {
    margin: 0 auto;
    margin-top: 40px;
    padding: 40px;
  }
}
