@media only screen and (max-width: 1200px) {
  #main_wrapper {
    width: 100vw;
  }
  #header_wrapp {
    width: 100vw;
    height: 50vh;
  }
  #header_wrapp .top_bar .logo {
    font-size: 30px !important;
  }
  #header_wrapp .top_bar .menu_wrapp ul {
    font-size: 25px !important;
  }
  .wrapper .container {
    padding: 40px 30px !important;
  }
  h1 {
    font-size: 60px !important;
  }
  h2 {
    font-size: 40px !important;
  }
  h3 {
    font-size: 28px !important;
  }
  h4 {
    font-size: 25px !important;
  }
  h5 {
    font-size: 22px !important;
  }
  #header_wrapp .banner_content .button {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 25px !important;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 40px !important;
    color: white;
    cursor: pointer;
  }

  #route_wrapp .calculate_wrapp {
    // filter: drop-shadow(0px 13px 29px rgba(0, 0, 0, 0.25));
    font-size: 18px !important;
    font-weight: 600 !important;
    // margin: 0 15px !important;
  }
  #route_wrapp .calculate_wrapp .methods {
    width: 100%;
    height: 30px !important;
  }
  #route_wrapp .calculate_wrapp .methods div:nth-child(1),
  #route_wrapp .calculate_wrapp .methods div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #route_wrapp .calculate_wrapp .calculate,
  #route_wrapp .calculate_wrapp .result {
    font-size: 18px !important;
  }
  .image {
    width: 100% !important;
    img {
      width: 500px;
    }
  }

  #table_wrapp .head {
    height: 80px !important;
  }
  #table_wrapp .col {
    font-size: 25px;
  }
  #footer_wrapp .footer .logo {
    font-size: 30px !important;
  }
  #footer_wrapp {
    font-weight: 400 !important;
    font-size: 18px !important;
  }
  #footer_wrapp .footer .contacts_wrapp .contact {
    img {
      min-width: 50px !important;
      min-height: 50px !important;
    }
  }
  #footer_wrapp .footer .anchors {
    margin-left: 20px !important;
  }
  #footer_wrapp .contact_form {
    margin: 0 auto !important;

    img {
      min-width: 20px !important;
      min-height: 20px !important;
      padding: 5px !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  #main_wrapper {
    width: 100% !important;
  }
  #header_wrapp {
    width: 100% !important;
    height: 50vh !important;
  }
  #header_wrapp .top_bar .logo {
    font-size: 20px !important;
  }
  #header_wrapp .top_bar .menu_wrapp ul {
    font-size: 15px !important;
  }
  .wrapper .container {
    padding: 10px 40px !important;
  }
  h1 {
    font-size: 45px !important;
  }
  h2 {
    font-size: 38px !important;
  }
  h3 {
    font-size: 25px !important;
  }
  h4 {
    font-size: 22px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  #header_wrapp .banner_content .button {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 25px !important;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 40px !important;
    color: white;
    cursor: pointer;
  }

  #route_wrapp .calculate_wrapp .sumbit {
    padding: 10px 0 !important;
  }

  #route_wrapp .calculate_wrapp {
    // filter: drop-shadow(0px 13px 29px rgba(0, 0, 0, 0.25));
    font-size: 18px !important;
    font-weight: 600 !important;
    // margin: 0 15px !important;
  }
  #route_wrapp .calculate_wrapp .methods {
    width: 100%;
    height: 30px !important;
  }
  #route_wrapp .calculate_wrapp .calculate,
  #route_wrapp .calculate_wrapp .result {
    font-size: 14px !important;
  }

  .image {
    width: 100% !important;
    img {
      width: 300px;
    }
  }

  #table_wrapp .head {
    height: 50px !important;
  }
  #table_wrapp .col {
    font-size: 18px;
  }
  #footer_wrapp .footer .logo {
    font-size: 24px !important;
  }
  #footer_wrapp {
    font-weight: 200 !important;
    font-size: 14px !important;
  }
  #footer_wrapp .footer .contacts_wrapp .contact {
    img {
      min-width: 35px !important;
      min-height: 35px !important;
      max-width: 35px !important;
      max-height: 35px !important;
    }
  }
  #footer_wrapp .footer .anchors {
    margin-left: 20px !important;
  }
  #footer_wrapp .contact_form {
    img {
      min-width: 40px !important;
      max-width: 40px !important;
      max-height: 40px !important;
      min-height: 40px !important;
      padding: 5px !important;
    }
    height: 400px !important;
  }
  #footer_wrapp .contact_form .form_item input {
    padding: 5px !important;
  }

  .step_wrapp {
    flex-direction: column !important;
    .w50 {
      width: 100% !important;
    }
    .cards_wrapp {
      margin-top: 20px !important;
      .calculate_wrapp,
      .contact_form {
        max-width: 600px;
        margin: 0 auto;
      }
      .submit {
        max-width: 600px !important;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .step_wrapp{
    padding: 20px !important;
  }

  .rounded{
    border-top-left-radius: 20px !important;
  }
  .head{
    .col{
      &:nth-child(2){
        border-top-right-radius: 20px !important;
      }
      &:nth-child(1){
        border-top-left-radius: 20px !important;
      }
    }
  }
  #main_wrapper {
    width: 100% !important;
    
  }
  #header_wrapp {
    width: 100% !important;
    height: 50vh !important;
    .container {
      margin-left: 0px !important;
      margin-bottom: 40px !important;
    }
  }

  .footer_content{
    flex-direction: column !important;
    .anchors, .social_media {
      text-align: center;
      justify-content: center;
      flex-direction: row !important;
    }
    .anchors {
      span{
        margin: 20px;
      }
    }
  }
  #header_wrapp .top_bar .logo {
    font-size: 18px !important;
  }
  #header_wrapp .top_bar .menu_wrapp ul {
    font-size: 12px !important;
    
  }

  .social_media {
    flex-direction: column !important;
  }
  .calculate_wrapp, .contact_form{
    min-width: auto !important;
    width: 100% !important;
  }

  #route_wrapp .calculate_wrapp .calculate img,
  #route_wrapp .calculate_wrapp .result img {
    height: 15px;
  }

  #route_wrapp .calculate_wrapp .methods div {
    padding: 10px 0 !important;
  }

  #header_wrapp .banner_content .button {
    font-size: 14px !important;
    padding: 5px 10px !important;
  }

  #route_wrapp .calculate_wrapp .calculate .ant-select *,
  #route_wrapp .calculate_wrapp .result .ant-select * {
    font-size: 14px;
  }

  #route_wrapp .calculate_wrapp .calculate .result_input,
  #route_wrapp .calculate_wrapp .result .result_input {
    padding: 5px 10px !important;
  }
  .wrapper .container {
    padding: 10px 40px !important;

    .w50 {
      width: 100% !important;
    }
  }
  h1 {
    font-size: 32px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  h4 {
    font-size: 18px !important;
  }
  h5 {
    font-size: 16px !important;
  }
  #header_wrapp .banner_content .button {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 25px !important;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 40px !important;
    color: white;
    cursor: pointer;
  }

  #route_wrapp .calculate_wrapp .sumbit {
    padding: 10px 0 !important;
  }

  #service_wrapp .container .cards_wrapp .card {
    width: 300px !important;
  }

  .cards_wrapp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #route_wrapp .calculate_wrapp {
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 10px 15px !important;
  }
  #route_wrapp .calculate_wrapp .methods {
    width: 100%;
    height: 20px !important;
  }
  #route_wrapp .calculate_wrapp .calculate,
  #route_wrapp .calculate_wrapp .result {
    font-size: 14px !important;
  }

  .image {
    text-align: center;
    width: 100% !important;
    img {
      width: 200px;
    }
  }

  #table_wrapp .head {
    height: 35px !important;
    font-size: 22px !important;
  }
  #table_wrapp .col {
    font-size: 12px;
  }
  #footer_wrapp .footer .logo {
    font-size: 18px !important;
  }
  #footer_wrapp {
    font-weight: 200 !important;
    width: 100% !important;
    font-size: 14px !important;
  }
  #footer_wrapp .footer .contacts_wrapp .contact {
    img {
      min-width: 35px !important;
      min-height: 35px !important;
      max-width: 35px !important;
      max-height: 35px !important;
    }
  }
  #footer_wrapp .footer .anchors {
    margin-left: 20px !important;
  }
  #footer_wrapp .contact_form {
    img {
      min-width: 40px !important;
      max-width: 40px !important;
      max-height: 40px !important;
      min-height: 40px !important;
      padding: 5px !important;
    }
    height: 350px !important;
  }
  #footer_wrapp .contact_form .form_item input {
    padding: 5px !important;
  }
  .footer {
    margin-bottom: 30px !important;
  }
  #footer_wrapp .contact_form .form_item label {
    margin-bottom: 5px !important;
  }
  #footer_wrapp .contact_form .form_item {
    margin: 15px 0 !important;
  }
}

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
@media only screen and (max-width: 480px) {
  #main_wrapper {
    width: 100% !important;
  }
  #header_wrapp {
    min-height: auto !important;
    width: 100% !important;
    height: 40vh !important;
    .container{
      margin-left:0 !important;
    }
  }
  #header_wrapp .top_bar .logo {
    font-size: 18px !important;
  }
  #header_wrapp .top_bar .menu_wrapp ul {
    font-size: 12px !important;
  }

  #route_wrapp .calculate_wrapp .calculate img,
  #route_wrapp .calculate_wrapp .result img {
    height: 15px;
  }

  #route_wrapp .calculate_wrapp .methods div {
    padding: 10px 0 !important;
  }

  #header_wrapp .banner_content .button {
    font-size: 14px !important;
    padding: 0 10px !important;
  }

  #route_wrapp .calculate_wrapp .calculate .ant-select *,
  #route_wrapp .calculate_wrapp .result .ant-select * {
    font-size: 14px;
  }

  #route_wrapp .calculate_wrapp .calculate .result_input,
  #route_wrapp .calculate_wrapp .result .result_input {
    padding: 5px 10px !important;
  }

  .step_wrapp{
    padding: 20px 0px !important;
  }

  .wrapper .container {
    padding: 10px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    .w50 {
      width: 100% !important;
    }
  }
  h1 {
    font-size: 24px !important;
  }
  h2 {
    font-size: 20px !important;
  }
  h3 {
    font-size: 18px !important;
  }
  h4 {
    font-size: 14px !important;
  }
  h5 {
    font-size: 13px !important;
  }
  #header_wrapp .banner_content .button {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 20px !important;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 40px !important;
    color: white;
    cursor: pointer;
  }

  #route_wrapp .calculate_wrapp .sumbit {
    padding: 10px 0 !important;
  }

  #service_wrapp .container .cards_wrapp .card {
    width: 300px !important;
  }

  .cards_wrapp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #route_wrapp .calculate_wrapp {
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 10px 15px !important;
  }
  #route_wrapp .calculate_wrapp .methods {
    width: 100%;
    height: 20px !important;
  }
  #route_wrapp .calculate_wrapp .calculate,
  #route_wrapp .calculate_wrapp .result {
    font-size: 14px !important;
    padding: 20px !important;
  }

  .image {
    text-align: center;
    width: 100% !important;
    img {
      width: 200px;
    }
  }

  #table_wrapp .head {
    height: 35px !important;
    font-size: 16px !important;
  }
  #table_wrapp .col {
    font-size: 12px;
  }
}
