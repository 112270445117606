@use '../../media/css/Colors.scss';
.auth_wrapp{
    overflow: hidden !important;
    img{
        width: 15rem;
        width: 15rem;
    }
    h5{
        margin-top: 0;
    }
    top: 10%;
    position: fixed;
    height: 100vh;
    width: 100vw;
    .auth{
        margin: 0 auto;
        width: 400px;
        text-align: center;
        input{
            font-family: "Oswald", sans-serif;
            border-radius: 10px;
            background-color: Colors.$clr-bg;
            outline: none;
            border: none;
            padding: 10px 20px;
            margin-bottom: 20px;
            font-size: 20px;
        }
    }
}
