* {
  font-size: 16px;
}
.title_big {
  font-size: 38px;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  margin-bottom: 0.9em;
  position: relative;
}
.title_medium {
  font-size: 28px;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 0.5em;
  position: relative;
}
.section {
  margin: 10px 0 30px 0;
}
