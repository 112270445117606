$clr-grey: #f4f7fb;
$clr-orange: #f07808;
$clr-black: #3c3c3c;

#header_wrapp {
  background-image: url("../../media/images/banner.jpg") ;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  min-height: 600px;
  .container {
    margin-left: 100px;
    margin-bottom: 100px;
  }
  .banner_content {
    // position: absolute;
    bottom: 200px;
    h2 {
      color: $clr-orange;
      text-shadow: 3px 5px 8px rgba(60, 60, 60, 0.58);
    }
    h3 {
      color: $clr-black;
    }
    .button {
      width: fit-content;
      font-size: 36px;
      font-weight: bold;
      padding: 10px 20px;
      border-radius: 50px;
      color: white;
      cursor: pointer;
      box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
      background-color: $clr-orange;
    }
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 600;
    li {
      max-height: fit-content;
      height: fit-content;
      cursor: pointer;
      display: inline-block;
      color: $clr-black;
      text-decoration: none;
      list-style: none;
      padding: 10px 20px;
      transition: 0.2s all;
      &:hover {
        background-color: $clr-orange;
        color: white;
      }
    }
  }
  
}
.logo {
  max-width: 100px !important;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Segoe UI";
}
.nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}


.mobile_logo {
  color: black;
  left: 20px;
  position: absolute;
  display: none;
}

.nav > .nav-list {
  display: flex;
  gap: 2rem;
  font-size: 20px;
}
.nav > .nav-list > li {
  list-style-type: none;
}
.nav > .nav-list > li a {
  text-decoration: none;
  color: #3c3c3c;
  cursor: pointer;
}
.nav > #nav-check {
  display: none;
}
@media (max-width: 900px) {

  
  .logo {
    max-width: 50px !important;
  }
  .mobile_logo{
    display: block;
  }

  .nav {
    background-color: #f07808;
    width: 100%;
  }
  .nav > .nav-btn {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 0.2rem;
    label {
      cursor: pointer;
    }
  }
  .nav-title {
    display: none;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .nav > .nav-list {
    position: absolute;
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    gap: 1rem;
    width: 100%;
    background-color: #f1f1f1;
    height: 0;
    transition: all 0.3s ease-in;
    top: 60px;
    left: 0;
    overflow: hidden;
  }
  .nav > .nav-list > li {
    width: 100%;
    margin-top: 1.5rem;
  }
  .nav > #nav-check:checked ~ .nav-list {
    height: calc(100vh - 50px);
  }
}
